import { post } from '@/utils/request';

// 列表
const listReq = (cond) => post({
  url: '/store/goods.handling.Recycle/list',
  data: {
    ...cond,
  },
});

export {
  listReq,
};

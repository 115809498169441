import { post } from '@/utils/request';

const auditReq = (data) => post({
  url: '/store/goods.handling.Recycle/audit',
  data: {
    ...data,
  },
});

export {
  auditReq,
};

<template>
  <div>
    <div class="card">
      <el-page-header
        @back="goBack" content="旧料处理单详情"
      >
      </el-page-header>
    </div>
    <Detail
      :arr="arr"
      :rowList1="rowList1"
      :tableData1="tableData1"
      :rowList2="rowList2"
      :tableData2="tableData2"
      @onChangePage1="handlePage"
      @onChangePage2="handlePage2"
      @onSizeChange="handleSizeChange"
      @onSizeChange2="handleSizeChange2"
    />
   </div>
</template>

<script>
import storage from 'good-storage';
import Detail from '@/components/goods/detail/Index.vue';
import { getAttrList } from '@/api/goods/goodsWarehousing/warehousing';
import { detailReq, detailGoodsListReq, getLogListReq } from '@/api/goods/goodsHandler/old/detail';
import moment from 'moment';

export default {
  data() {
    return {
      page2: 1,
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      page_num2: this.$store.state.pageSizes[0],
      userInfo: {},
      arr: [
        {
          id: 0,
          name: '旧料单号',
          value: '',
          prop: 'recycle_number',
        },
        {
          id: 1,
          name: '总数量',
          value: '',
          prop: 'recycle_total_num',
        },
        {
          id: 2,
          name: '审核状态',
          value: '',
          prop: 'recycle_status',
        },
        {
          id: 3,
          name: '审核时间',
          value: '',
          prop: 'audit_time',
        },
        {
          id: 4,
          name: '审核人员',
          value: '',
          prop: 'audit_user_name',
        },
        {
          id: 5,
          name: '添加人',
          value: '',
          prop: 'create_user_name',
        },
        {
          id: 6,
          name: '添加时间',
          value: '',
          prop: 'create_time',
        },
        {
          id: 7,
          name: '原因说明',
          value: '',
          prop: 'audit_remark',
        },
      ],
      rowList1: [],
      tableData1: {
        list: [],
        total: 0,
      },
      rowList2: [
        {
          label: '操作时间',
          prop: 'create_time',
          width: 200,
        },
        {
          label: '操作记录',
          prop: 'msg',
        },
        {
          label: '操作人',
          prop: 'create_user',
          width: 200,
        },
      ],
      tableData2: {
        list: [],
        total: 0,
      },
    };
  },
  components: {
    Detail,
  },
  props: {
    // allot_id: Number,
    propObj: {
      type: Object,
      default: () => (
        {
          key: 'recycle_id',
          value: 0,
        }
      ),
    },
    goods_type_id: {
      type: Number,
      default: 36,
    },
  },
  created() {
    const userInfo = storage.get('userInfo');
    this.userInfo = userInfo;
    this.getDetail();
    this.handleArrList();
    this.getDetailGoods();
    this.getLogList();
  },
  methods: {
    getLogList() {
      getLogListReq({ recycle_id: this.propObj.value, page: this.page2, limit: this.page_num2 })
        .then((res) => {
          if (res.code === 1) {
            res.data.list.forEach((item) => {
              item.create_time && (item.create_time = moment(item.create_time * 1000).format('YYYY-MM-DD HH:mm:ss'));
            });
            this.tableData2 = res.data;
          }
        });
    },
    // 获取列表的列
    handleArrList() {
      getAttrList({ goods_type_id: this.goods_type_id })
        .then((res) => {
          if (res.code === 1) {
            const newArr = [];
            res.data.forEach((item) => {
              newArr.push({
                label: item.attr_name,
                prop: '' + item.attr_fields,
                span: item.attr_fields === 'goods_number' ? 2 : 0,
              });
            });
            newArr.splice(1, 0, {
              label: '修改标志',
              prop: 'label',
            });
            this.rowList1 = newArr;
          }
        });
    },
    getDetail() {
      detailReq(this.propObj.value)
        .then((res) => {
          if (res.code === 1) {
            const data = res.data;
            data.audit_time && (data.audit_time = moment(data.audit_time * 1000).format('YYYY-MM-DD HH:mm:ss'));
            data.create_time && (data.create_time = moment(data.create_time * 1000).format('YYYY-MM-DD HH:mm:ss'));
            this.arr.forEach((item) => {
              item.value = (res.data[item.prop] ? res.data[item.prop] : '--');
            });
          }
        });
    },
    getDetailGoods() {
      detailGoodsListReq({ [this.propObj.key]: this.propObj.value, page: this.page, page_num: this.page_num })
        .then((res) => {
          if (res.code === 1) {
            const newArr = [];
            res.data.list.forEach((item) => {
              newArr.push({ ...item.old_info, label: '原始', goods_number: item.goods_number });
              newArr.push({ ...item.new_info, label: '修改', goods_number: item.goods_number });
            });
            this.tableData1 = {
              list: newArr,
              total: res.data.total,
            };
          }
        });
    },
    goBack() {
      this.$emit('hide');
    },
    handleSizeChange(page_num) {
      this.page = 1;
      this.page_num = page_num;
      this.getDetailGoods();
    },
    handleSizeChange2(page_num) {
      this.page2 = 1;
      this.page_num2 = page_num;
      this.getLogList();
    },
    handlePage(page) {
      this.page = page;
      this.getDetailGoods();
    },
    handlePage2(page) {
      this.page2 = page;
      this.getLogList();
    },
  },
};
</script>

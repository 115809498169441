<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" content="旧料处理单审核"> </el-page-header>
    </div>
    <div class="table" style="margin-bottom: 80px;">
      <el-table :data="tableData.list" :row-class-name="handleRow" :span-method="objectSpanMethod" border>
        <template v-for="(item, index) in rowList">
              <el-table-column :key="index" :label="item.label" :prop="item.prop" align="center">
                <template v-slot="scope">
                  <template v-if="item.isImg">
                    <el-image :src="scope.row[item.prop]" :preview-src-list="[scope.row[item.prop]]">
                      <div slot="error" class="image-slot">
                        <img src="../../../../assets/images/no_img.png" />
                      </div>
                    </el-image>
                  </template>
                  <template v-else-if="item.prop === 'goods_name'">
                    <div style="display: flex; align-items: center;">
                      {{scope.row.goods_name}}
                      <template v-for="item in scope.row.goods_tag_pic">
                        <img :src="item" :key="item" style="margin-left: 5px; height: 15px;" />
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <span v-if="scope.row.index % 2">{{scope.row[item.prop] || '--'}}</span>
                    <span v-else :class="scope.row[item.prop] == tableData.list[scope.row.index - 2][item.prop]? '': scope.row.label === '修改'? 'red': ''">
                      {{scope.row[item.prop] || '--'}}
                    </span>
                  </template>
                </template>
              </el-table-column>
            </template>
      </el-table>
    </div>
    <el-dialog
      title="请输入原因"
      :visible.sync="reasonVisible"
      width="30%"
      :before-close="handleClose">
      <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" v-model="audit_remark"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleReason" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
    <div class="btns">
      <el-button type="primary" style="margin-right: 20px;" @click="toPass" :loading="loading">
        审核通过
      </el-button>
      <el-button type="primary" plain @click="toFail" :loading="loading">审核不通过</el-button>
    </div>
  </div>
</template>

<script>
import storage from 'good-storage';
import { detailGoodsListReq } from '@/api/goods/goodsHandler/old/detail';
import { auditReq } from '@/api/goods/goodsHandler/old/examine';
import { getAttrList } from '@/api/goods/goodsWarehousing/warehousing';

export default {
  name: 'examine',
  data() {
    return {
      audit_remark: '',
      reasonVisible: false,
      warehouseList: [],
      form: {},
      dialogVisible: false,
      loading: false,
      userInfo: {},
      tableData: {},
      rowList: [],
    };
  },
  props: {
    recycle_id: Number,
    goods_type_id: Number,
  },
  created() {
    const userInfo = storage.get('userInfo');
    this.userInfo = userInfo;
    this.handleArrList();
    this.getDetailGoods();
  },
  methods: {
    handleClose() {
      this.reasonVisible = false;
      this.audit_remark = '';
    },
    handleReason() {
      this.audit_remark = this.audit_remark.trim();
      if (!this.audit_remark) {
        this.$message.error('请输入原因');
        return;
      }
      this.handleAudit(30);
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      for (let i = 0; i < this.rowList.length; i ++) {
        const item = this.rowList[i];
        if (item.span) {
          this.border = true;
          if (columnIndex === i) {
              if (rowIndex % item.span === 0) {
                return {
                  rowspan: item.span,
                  colspan: 1,
                };
              } else {
                return {
                  rowspan: 0,
                  colspan: 0
                };
              }
          }
        }
      }
    },
    // 获取列表的列
    handleArrList() {
      getAttrList({ goods_type_id: this.goods_type_id })
        .then((res) => {
          if (res.code === 1) {
            const newArr = [];
            res.data.forEach((item) => {
              newArr.push({
                label: item.attr_name,
                prop: '' + item.attr_fields,
                span: item.attr_fields === 'goods_number' ? 2 : 0,
              });
            });
            newArr.splice(1, 0, {
              label: '修改标志',
              prop: 'label',
            });
            this.rowList = newArr;
          }
        });
    },
    handleTrue() {
      this.dialogVisible = false;
      this.handleRecipient(40);
    },
    handleAudit(recycle_status) {
      this.loading = true;
      auditReq({ recycle_id: this.recycle_id, recycle_status: recycle_status, audit_remark: this.audit_remark })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.$emit('hide');
            this.$emit('getList');
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleRecipient(allot_status) {
      this.loading = true;
      recipientReq({ recycle_id: this.recycle_id, allot_status: allot_status, warehouse_id: this.form.warehouse_id })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.$emit('hide');
            this.$emit('getList');
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toPass() {
      this.$confirm('确定要审核通过吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        // 审核通过
        this.handleAudit(20);
      });
    },
    toFail() {
      this.reasonVisible = true;
      // this.$confirm('确定要审核不通过吗?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // }).then(() => {
      //   // 审核不通过
      //   this.handleAudit(30);
      // });
    },
    handleRow({ row, rowIndex }) {
      row.index = rowIndex + 1;
    },
    getDetailGoods() {
      detailGoodsListReq({ recycle_id: this.recycle_id })
        .then((res) => {
          if (res.code === 1) {
            const newArr = [];
            res.data.list.forEach((item) => {
              newArr.push({ ...item.old_info, label: '原始', recycle_id: item.recycle_id, goods_id: item.goods_id, goods_info: item.goods_info, goods_number: item.goods_number });
              newArr.push({ ...item.new_info, label: '修改', recycle_id: item.recycle_id, goods_id: item.goods_id, goods_info: item.goods_info, goods_number: item.goods_number });
            });
            this.tableData = {
              list: newArr,
              total: res.data.total,
            };
          }
          console.log(this.tableData);
        });
    },
    goBack() {
      this.$emit('hide');
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .red {
  color: #fd563a;
}
/deep/ .image-slot {
  width: 80px;
  height: 80px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 10px;
  }
}
/deep/ .el-image {
  img {
    object-fit: cover;
    margin: 10px;
  }
}
.btns {
  padding: 20px;
  background: white;
  margin-top: 20px;
  position: fixed;
  left: 84px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
}
</style>

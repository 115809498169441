import { post } from '@/utils/request';

// 调拨详情
const detailReq = (recycle_id) => post({
  url: '/store/goods.handling.Recycle/detail',
  data: {
    recycle_id,
  },
});

// 调拨详情--商品列表
const detailGoodsListReq = ({ recycle_id, page, page_num }) => post({
  url: '/store/goods.handling.Recycle/detailGoodsList',
  data: {
    recycle_id,
    page,
    page_num,
  },
});

// 日志列表
const getLogListReq = ({ recycle_id, page, limit }) => post({
  url: '/store/goods.handling.Recycle/getLogList',
  data: {
    recycle_id,
    page,
    limit,
  },
});

export {
  detailReq,
  detailGoodsListReq,
  getLogListReq,
};

<template>
  <div class='content'  style="background: #f6f7fb;">
    <div v-if="!addVisiable && !detailVisiable && !examineVisiable">
      <div class='card' style='display: flex;'>
        <div class="left">
          <el-button type='primary' @click='dialogVisible = true' icon='el-icon-plus'>新增旧料处理单</el-button>
          <!-- <el-button type='primary' plain @click='handleExport' icon='el-icon-upload2' :loading="loading3">导出</el-button> -->
        </div>
        <div class='line'></div>
        <div class="middle">
          <Cond @onCond="handleCond" function_code="recycle" />
        </div>
        <div class="right">
          <TableConf function_code="recycle" @onRowList="handleRowList"/>
        </div>
      </div>
      <div class="total">
          <div class="total-right">
            <div class="total-right-item">总数量 <span>{{ data.goods_total || 0}}</span></div>
            <div class="total-right-item">总净金重 <span>{{ data.gold_weight || '0.00'}}</span></div>
          </div>
      </div>
      <div class='table' v-if="rowList.length" style="border: 1px solid #ddd; border-radius: 6px; overflow: hidden;">
        <el-table :data='data.list' stripe v-loading="loading" height="660" @row-dblclick="handleDalclick">
          <template v-for='(item, index) in rowList'>
            <el-table-column
              v-if="item.filed_status"
              align='center'
              :key='index'
              :prop="item.field_alias"
              :label='item.field_text'
              :width="item.t_width"
              :filters="item.filed_status"
              :filter-method="filterHandler"
            >
              <template slot-scope="scope">
                <template v-if="item.field_alias !== 'recycle_status'">
                  {{scope.row[item.field_alias] ? scope.row[item.field_alias]: '--'}}
                </template>
                <template v-else>
                  <span>
                    {{scope.row[item.field_alias] === 10? '待审核': scope.row[item.field_alias] === 20? '审核通过': scope.row[item.field_alias] === 30? '审核不通过': ''}}
                  </span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              align='center'
              :key='index'
              :prop="item.field_alias"
              :label='item.field_text'
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <template v-if="item.field_alias !== 'warehouse_status'">
                  {{scope.row[item.field_alias] ? scope.row[item.field_alias]: '--'}}
                </template>
                <template v-else>
                  <el-switch
                    v-model='scope.row.warehouse_status'
                    :active-value="10"
                    :inactive-value="20"
                    :disabled="scope.row.hierarchy_id == 1"
                    @change="handleSwitch(scope.row)"
                  >
                  </el-switch>
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column align='center' fixed='right' field_alias='operation' label='操作'>
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleChange($event, scope.row)"
                >
                  <el-option label="详情" :value="1"></el-option>
                  <el-option label="审核" :value="2" v-if="scope.row.recycle_status === 10"></el-option>
                  <el-option label="查看失败原因" :value="3" v-if="scope.row.recycle_status === 30"></el-option>
                  <el-option label="重新编辑" :value="4" v-if="scope.row.recycle_status === 30"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
        <div class='pagina'>
          <el-pagination
            background
            :current-page="page"
            @size-change="handleSizeChange"
            layout='total, sizes, prev, pager, next, jumper'
            :total='data.total'
            :page-size='$store.state.pageSizes[0]'
            :page-sizes="$store.state.pageSizes"
            @current-change='handleCurrentChange'
          ></el-pagination>
        </div>
      <el-dialog
        title="请先选择品类"
        :visible.sync="dialogVisible"
        width="30%"
        :destroy-on-close="true"
        :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <el-form-item label="品类" label-width="100px" required prop="goods_type_id">
            <!-- <el-select v-model="form.goods_type_id">
              <template v-for="item in select">
                  <el-option :label="item.goods_type_name" :value="item.goods_type_id" :key="item.goods_type_id">
                    <span :style="{marginLeft: item.span * 8 +'px'}">{{item.goods_type_name}}</span>
                  </el-option>
                </template>
            </el-select> -->
            <SelectTree
              id="select"
              :props="props"
              :options="treeData"
              :clearable="isClearable"
              :accordion="isAccordion"
              @getValue="getValue($event)"
            />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <el-dialog
      title="失败原因"
      :visible.sync="reasonVisible"
      width="30%"
      :before-close="handleClose">
      <span>
        {{currRow.audit_remark || '--'}}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
    <Add
      v-if="addVisiable"
      :recycle_id="currRow.recycle_id"
      :goods_type_id="form.goods_type_id"
      :text="text"
      @hide="handleHideAdd"
      @getList="getList"
    />
    <Detail
      v-if="detailVisiable"
      :goods_type_id="currRow.goods_type_id"
      :propObj="propObj"
      @hide="handleHideDetail"
    />
    <Examine
      v-if="examineVisiable"
      :goods_type_id="currRow.goods_type_id"
      :recycle_id="currRow.recycle_id"
      @hide="handleHideExamine"
      @getList="getList"
    />
  </div>
</template>

<script>
import { listReq } from '@/api/goods/goodsHandler/old/list';
import {
  getListReq as getTypeListReq
} from '@/api/goods/goodsConfig/classify';
import moment from 'moment';
import Cond from '@/components/cond/Index.vue';
import TableConf from '@/components/tableConf/Index.vue';
import Add from "./Add.vue";
import Detail from "./Detail.vue";
import Examine from "./Examine.vue";

export default {
  data() {
    return {
      text: '',
      isClearable: true, // 可清空（可选）
      isAccordion: true, // 可收起（可选）
      props: {
        value: "goods_type_id",
        label: "goods_type_name",
        children: "children",
      },
      treeData: [],
      loading3: false,
      reasonVisible: false,
      currRow: {},
      select: [],
      propObj: {},
      addVisiable: false,
      detailVisiable: false,
      examineVisiable: false,
      rowList: [
        // {
        //   field_text: '仓库名称',
        //   field_alias: 'warehouse_name',
        // },
        // {
        //   field_text: '所属门店',
        //   field_alias: 'hierarchy_name',
        // },
        // {
        //   field_text: '状态',
        //   field_alias: 'warehouse_status',
        // },
        // {
        //   field_text: '添加人',
        //   field_alias: 'create_user_name',
        // },
        // {
        //   field_text: '添加时间',
        //   field_alias: 'create_time',
        // },
      ],
      loading2: false,
      rules: {
        goods_type_id: [
          { required: true, message: '请选择品类' },
        ],
      },
      merchantList: [],
      loading: false,
      form: {},
      dialogVisible: false,
      total: 0,
      tableVisiable: false,
      cond: {},
      page: 1,
      limit: this.$store.state.pageSizes[0],
      // coordinate: { column: -1, row: -1 },
      data: [],
    };
  },
  components: {
    Cond,
    TableConf,
    Add,
    Detail,
    Examine,
  },
  created() {
    this.getList();
    this.getTypeList();
  },
  methods: {
    handleDalclick(row) {
      this.currRow = row;
      this.propObj = {
        key: 'recycle_id',
        value: row.recycle_id,
      };
      this.detailVisiable = true;
      this.cancelTable();
    },
    // 取值
    getValue(value) {
      const form = { ...this.form };
      form.goods_type_id = value;
      this.form = form;
      this.$nextTick(() => {
        const dom = document.getElementById('select').getElementsByTagName('input')[0];
        this.text = dom.value;
      });
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    handleHideDetail() {
      this.detailVisiable = false;
      this.currRow = {};
      this.form = {};
    },
    handleHideExamine() {
      this.form = {};
      this.currRow = {};
      this.examineVisiable = false;
    },
    handleHideAdd() {
      this.form = {};
      this.currRow = {};
      this.addVisiable = false;
    },
    handleArr(arr) {
      const newArr = [];
      function poling(arr, span) {
        arr.forEach((item) => {
          if (span || span === 0) {
            item.span = span + 1;
            item.goods_type_name = '├─' + item.goods_type_name;
          } else {
            item.span = 0;
          }
          newArr.push(item);
          if (item.children) {
            poling(item.children, item.span);
          }
        });
      }
      // 深复制
      const aArr = JSON.parse(JSON.stringify(arr));
      poling(aArr);
      return newArr;
    },
    getTypeList() {
      getTypeListReq({
        type: [20],
      })
        .then((res) => {
          if (res.code === 1) {
            this.treeData = res.data;
          }
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 成功的操作
          this.addVisiable = true;
          this.dialogVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    cancelTable() {
      const coordinate = { ...this.coordinate };
      coordinate.row = -1;
      coordinate.column = -1;
      this.coordinate = coordinate;
      this.tableVisiable = false;
      this.tableVisiable2 = false;
      this.currRow.select = '';
    },
    filterHandler(value, row, column) {
      const { property } = column;
      return row[property] === value;
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    handleCond(cond) {
      this.page = 1;
      this.cond = { ...cond };
      this.getList();
    },
    getList() {
      this.loading = true;
      listReq({ ...this.cond, page: this.page, page_num: this.limit })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            // res.data.list.forEach((item) => {
            //   item.create_time && (item.create_time = moment(item.create_time * 1000).format('yyyy-MM-DD hh:mm:ss'));
            //   item.audit_time && (item.audit_time = moment(item.audit_time * 1000).format('yyyy-MM-DD hh:mm:ss'));
            // });
            this.data = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleClose () {
      this.dialogVisible = false;
      this.reasonVisible = false;
      this.form = {};
      this.cancelTable();
    },
    fetchData() {
      this.getList();
    },
    /*
     *  导出
     */
    handleExport() {
      this.loading3 = true;
      listReq({ ...this.cond, page: this.page, page_num: this.limit, function_code: 'recycle', export: 2 })
        .then((res) => {
          this.loading3 = false;
          let blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `${moment().format('yyyy-MM-DD')}旧料处理单列表.csv`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.loading3 = true;
        });
    },
    /*
     *  新增入库
     */
    handleAdd() {
      this.addVisiable = true;
    },
    /*
     *  检索
     */
    handleChange(val, row) {
      this.currRow = row;
      this.propObj = {
        key: 'recycle_id',
        value: row.recycle_id,
      };
      if (val === 1) {
        this.detailVisiable = true;
        this.cancelTable();
      }
      if (val === 2) {
        this.examineVisiable = true;
        this.cancelTable();
      }
      if (val === 3) {
        this.reasonVisible = true;
      }
      if (val === 4) {
        this.addVisiable = true;
        const form = { ...this.form };
        form.goods_type_id = row.goods_type_id;
        this.form = form;
        this.addVisiable = true;
        this.cancelTable();
      }
    },
    /*
     *  分页操作
     */
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    /*
     *  关闭列表配置弹窗
     */
    // cancelTable() {
    //   const coordinate = { ...this.coordinate };
    //   coordinate.row = -1;
    //   coordinate.column = -1;
    //   this.coordinate = coordinate;
    //   this.tableVisiable = false;
    // },
    /*
     *  关闭选择入库类型弹窗
     */
    cancelAdd() {
      this.addVisiable = false;
    },
  },
};
</script>

<style lang="less" scoped>
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  // padding: 0 20px;
  // background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: white;
  margin-bottom: 10px;
  border-radius: 0px 0px 6px 6px;
  &-left {
    // flex-shrink: 0;
  }
  &-right {
    display: flex;
    flex: 1;
    &-item {
      font-size: 15px;
      // padding: 0 10px;
      width: 15%;
      // border-right: 1px solid #ddd;
      text-align: center;
      color: #909399;
      span {
        font-size: 16px;
        color: #E7541E;
      }
    }
  }
}
.middle {
  flex: 1;
}
</style>
